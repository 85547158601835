import React, { useState } from "react";

const Dropdown = ({ options, selectedOption, setSelectedOption }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelectClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  return (
    <div className="product-filter-dropdown">
      <div
        className={`product-filter-select ${
          isDropdownOpen ? "select-clicked" : ""
        }`}
        onClick={handleSelectClick}
      >
        <span className="product-filter-selected">
          {selectedOption || "Select Category"}
        </span>
        <div
          className={`product-filter-caret ${
            isDropdownOpen ? "product-filter-caret-rotate" : ""
          }`}
        ></div>
      </div>
      <ul
        className={`product-filter-menu ${
          isDropdownOpen ? "product-filter-menu-open" : ""
        }`}
      >
        {options.map((option, index) => (
          <li
            key={index}
            className={`product-filter-option ${
              selectedOption === option ? "product-filter-active" : ""
            }`}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
