import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaLocationArrow } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer dark-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h5>
                Vtrade Goods Wholesalers<br></br>
                <strong>Co.L.L.C</strong>
              </h5>
              <br></br>
              <p>
                <FaLocationArrow /> VTRADE GOODS WHOLESALERS LLC PO BOX 1334 ,
                AG HOUSE BUILDING , M FLOOR , OFFICE NUMBER 13 , SALAH AL DIN
                STREET , DEIRA DUBAI,UAE.<br></br>
                <Link to="tel:+971585019214" style={{ color: "white" }}>
                  <FaPhoneAlt /> +971 58 501 9214
                </Link>
                <br />
                <Link to="mailto:say@vtradellc.com" style={{ color: "white" }}>
                  <HiOutlineMail /> say@vtradellc.com
                </Link>
              </p>
            </div>
            <div className="col-lg-4">
              <h5>Usefull Links</h5>
              <div className="px-4" id="footer-menu">
                <ul style={{ listStyleType: "none", color: "white" }}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/brand">Brands </Link>
                  </li>
                  <li>
                    <Link to="/about">About </Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div class="newsletter-section">
                <div class="newsletter-content text-center">
                  <h2>Stay Updated with Our Newsletter</h2>

                  <div class="newsletter-form">
                    <input
                      type="email"
                      class="form-control text-center"
                      placeholder="Your email address"
                      aria-label="Email address"
                    />
                  </div>
                  <div class="newsletter-form">
                    <button class="btn btn-primary" type="submit">
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>

              <h5 className="sitename mt-3">Our Social Networks</h5>
              <div className="social-links d-flex justify-content-center">
                <Link to="">
                  <i className="bi bi-twitter-x"></i>
                </Link>
                <Link to="">
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link to="">
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link to="">
                  <i className="bi bi-skype"></i>
                </Link>
                <Link to="">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="copyright">
              <span>Copyright</span>{" "}
              <strong className="px-1 sitename">VTrades</strong>{" "}
              <span>All Rights Reserved</span>
            </div>
            <div className="credits text-white">
              Designed by{" "}
              <Link className="text-white" to="https://brandingworld.net/">
                BrandingWorld
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
