import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  React.useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);
  return (
    <div>
      <div className="bg-white" style={{ height: "80px", width: "100%" }}></div>
      <section id="about" className="about sec tion">
        <div className="container section-title" data-aos="fade-up">
          <h2>About</h2>
          <p>Who we are</p>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 content text-justify"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <p>
                At trade we delight and adhere to a philosophy "to access
                customer's hearts through their sense of taste," and revolves
                around our meticulous attention to detail. We invest a
                significant amount of effort in fostering our relationships with
                our clients. We at VTrade LLC have been successful in bringing
                the flavours of food and beverages to millions of households
                around the world. Were quick, dynamic, resilient, versatile, and
                agile. There's no bureaucratic hierarchy to step over, only a
                committed team of individuals who are aware of and focused on
                your requirements.{" "}
              </p>
              <section id="features" className="features section">
                <div className="container">
                  <ul
                    className="nav nav-tabs row  d-flex"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <li className="nav-item col-1 d-none d-lg-block">
                      <a
                        className="nav-link active show"
                        data-bs-toggle="tab"
                        data-bs-target="#features-tab-1"
                      >
                        {/* <i className="bi bi-binoculars"></i> */}
                        <h4 className="d-none d-lg-block">Who</h4>
                      </a>
                    </li>
                    <li className="nav-item col-1 d-none d-lg-block">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#features-tab-2"
                      >
                        {/* <i className="bi bi-box-seam"></i> */}
                        <h4 className="d-none d-lg-block">Why</h4>
                      </a>
                    </li>
                    <li className="nav-item col-1 d-none d-lg-block">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#features-tab-3"
                      >
                        {/* <i className="bi bi-brightness-high"></i> */}
                        <h4 className="d-none d-lg-block">Where</h4>
                      </a>
                    </li>
                  </ul>

                  <div
                    className="tab-content"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div
                      className="tab-pane fade active show"
                      id="features-tab-1"
                    >
                      <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                          <p className="fst-italic">
                            Vtrade Goods Wholesaler LLC is one of the top
                            importers, exporters, and distributors of
                            high-quality food and non-food products. The
                            extraordinary combination of premium culinary
                            products and value-added services that we at Vtrade
                            are proud to provide to our esteemed clientele is
                            something we take great pride in. We are a global
                            business in the FMCG (Food & Non-food) products like
                            Beverages, Confectionary, Grocery, Snacks, Coffee,
                            Milk powder, Pet food, Cosmetics, Baby care range,
                            household items, and others. We provide the best
                            selection of premium products, including dry,
                            chilled, and frozen options, at the most competitive
                            pricing to meet the demands of the FMCG service,
                            retail, and wholesale markets. We intend to provide
                            our clients with personalised consolidation
                            solutions and FMCG merchandise on a global scale. We
                            also specialise in creating tailored labels based on
                            the client's request.
                          </p>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 text-center">
                          <img
                            src="assets/img/working-1.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="features-tab-2">
                      <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                          <p>
                            Vtrade is renowned for its extraordinary quality
                            standards, diversity of brands, innovative supply
                            chain, and highest level of customer service. We
                            exclusively procure top-quality products that add
                            more worth and perfection to your creations,
                            assisting you in solidifying a long-lasting
                            relationship with your customers. At Vtrade, we go
                            the extra mile to provide competitive consolidation
                            solutions based on the needs of the client and
                            assure that every client receives the very best of
                            our services. In partnership with leading brand
                            partners, we make a concerted effort to create
                            cutting-edge and user-friendly solutions.
                          </p>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 text-center">
                          <img
                            src="assets/img/working-1.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="features-tab-3">
                      <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                          <p>
                            We have our visible presence in Middle Eastern,
                            Asian, African, and Oceanic countries. <br></br>
                            Currently, we operate from Dubai but we're seeking
                            to expand to other nations too.
                          </p>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 text-center">
                          <img
                            src="assets/img/working-1.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="container section-title" data-aos="fade-up">
                <p>Our Strength</p>
                <ul>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>Inovative Solutions</span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>Exemplary Effectiveness</span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>Invariably Clean And Available To Assist</span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>
                      Customer - Oriented.Decticated And Responsible Personel
                    </span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>Pristine Quality Products</span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>Dynamic Response Time</span>
                  </li>
                  <li>
                    <i className="bi bi-check2-circle"></i>{" "}
                    <span>Profound Individual Service</span>
                  </li>
                </ul>
              </div>
              <div className="container section-title" data-aos="fade-up">
                <p>Our Core Values</p>
                <strong>Integrity:</strong> We place a high value on qualitative
                standards. The core of our ideology is that we ought to operate
                ourselves according to the greatest ethical standards. We are
                honest and upfront, and we appreciate and act fairly toward
                everyone.<br></br>
                <strong>Clients Satisfaction:</strong> By being sincere with the
                promises we make and the connections we establish, we are
                dedicated to creating fantastic client experiences.<br></br>
                <strong>Quality:</strong> The calibre of our products and
                services will reveal how well we comprehend the value of quality
                and how it is addressed, resulting in the upholding of the
                highest standards.<br></br>
                <strong>Explicitly Open-Minded:</strong> Effective business
                performance relies on open communication. Well always let you
                know what's on our minds and we expect the same from you. No
                secrets.<br></br>
                <strong>Accountability:</strong> More than just holding
                individuals and groups accountable for their performance results
                is necessary to create an accountability culture. We develop
                trust both internally and publicly by taking responsibility for
                our actions (and inactions).<br></br>
                <strong>Fairness:</strong> Treating each individual with the
                respect and civility that we all expect and deserve
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
