import React, { useEffect, useState } from "react";
import {
  fetchbrandproduct,
  fetchcategorylist,
  fetchsearch,
  fetchfilter,
} from "./Apiservice";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaSearch } from "react-icons/fa";
import Dropdown from "./Dropdown";
import Loading from "./Loading";

const Brandproduct = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { brandId } = useParams(); // Get the brandId from the URL parameters

  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchbrandproduct(brandId);
        setProducts(data);
        setFilteredProducts(data); // Initially, show all products
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const getCategories = async () => {
      try {
        const data = await fetchcategorylist();
        setCategories(data);
      } catch (error) {
        setError(error);
      }
    };

    getProducts();
    getCategories();
  }, [brandId]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        try {
          const data = await fetchsearch(searchTerm);
          setFilteredProducts(data);
        } catch (error) {
          setError(error);
        }
      } else if (selectedCategory) {
        try {
          const data = await fetchfilter(selectedCategory);
          setFilteredProducts(data);
        } catch (error) {
          setError(error);
        }
      } else {
        setFilteredProducts(products);
      }
    };

    fetchSearchResults();
  }, [searchTerm, selectedCategory, products]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryChange = (category) => {
    const selectedCategoryId = categories.find(
      (cat) => cat.name === category
    )?.id;
    setSelectedCategory(selectedCategoryId || "");
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>Error loading products.</div>;

  return (
    <div>
      <section id="team" className="team section mt-5">
        <div className="product-header container">
          <div className="container section-title" data-aos="fade-up">
            <h2>Products</h2>
            <p>Our Products</p>
          </div>
          <div className="product-search">
            <div className="input-group ">
              <div className="form-outline" data-mdb-input-init>
                <input
                  id="search-input"
                  type="search"
                  className="form-control"
                  placeholder=" "
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <label className="form-label" htmlFor="search-input">
                  Search
                </label>
                <button
                  id="search-button"
                  type="button"
                  className="btn btn-primary"
                >
                  <FaSearch />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container product-page">
          <div className="product-filter">
            <div className="product-filter-heading">Filter</div>
            <div className="product-filter-dropdownbox">
              <h1>Category</h1>
              <Dropdown
                options={categories.map((cat) => cat.name)} // Populate dropdown with category names
                selectedOption={
                  categories.find((cat) => cat.id === selectedCategory)?.name ||
                  ""
                }
                setSelectedOption={handleCategoryChange}
              />
            </div>
          </div>
          <div className="row gy-4">
            {filteredProducts.map((product, index) => (
              <div
                className="col-12 col-md-6 col-lg-3 mb-4"
                data-aos="fade-up"
                data-aos-delay={`${100 + index * 100}`}
                key={product.id}
                style={{ height: "350px", width: "260px" }}
              >
                <div className="team-member col-12">
                  <Link to={`/product-detail/${product.id}`}>
                    <div className="member-img">
                      <img
                        src={product.image}
                        className="img-fluid"
                        alt={product.name}
                      />
                    </div>
                    <div className="member-info">
                      <h4>{product.name}</h4>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brandproduct;
