import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const handleDownload = () => {
    const link = document.createElement("a");

    link.href = "/VtradeGroups.pdf";
    link.download = "VtradeGroups.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="bg-black" style={{ height: "80px", width: "100%" }}></div>
      <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </div>

        <div className="container mt-4" data-aos="fade" data-aos-delay="100">
          <div className="row gy-4">
            <div className="col-lg-3">
              <div
                className="info-item d-flex"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
                  <p>
                    VTRADE GOODS WHOLESALERS LLC PO BOX 1334 , AG HOUSE BUILDING
                    , M FLOOR , OFFICE NUMBER 13 , SALAH AL DIN STREET , DEIRA
                    DUBAI,UAE.
                  </p>
                </div>
              </div>
              <div
                className="info-item d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <i className="bi bi-telephone flex-shrink-0"></i>
                <div>
                  <h3>Call Us</h3>
                  <p>
                    {" "}
                    <Link to="tel:+971585019214">+971 58 501 9214</Link>
                  </p>
                </div>
              </div>
              <div
                className="info-item d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email Us</h3>
                  <p>
                    <Link to="mailto:say@vtradellc.com">say@vtradellc.com</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <form
                action="forms/contact.php"
                method="post"
                className="php-email-form"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                    />
                  </div>
                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required=""
                    ></textarea>
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="col-lg-3"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "justify",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <p>
                Welcome to vtrade! We're excited to offer you an exclusive look
                at our extensive range of products through our e-catalogue. This
                PDF contains detailed information about the high-quality items
                we offer, including fresh produce, pantry staples, household
                essentials, and much more.
              </p>
              <p>
                By downloading and viewing our e-catalogue, you can explore the
                variety of products available at your fingertips. Whether you're
                looking for the best deals, new arrivals, or simply want to know
                more about what we have in store, our e-catalogue has it all.
              </p>
              <button onClick={handleDownload} className="catalogue">
                Download Catalogue
              </button>
            </div>
          </div>
        </div>
        <div className="container mt-3" data-aos="fade" data-aos-delay="100">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57728.08406174062!2d55.32459715262306!3d25.27040870254701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d0065b900df%3A0xd93dd212196bc9b0!2sAG%20House!5e0!3m2!1sen!2sin!4v1727341760704!5m2!1sen!2sin"
            style={{ width: "100%", height: "400px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
