import axios from "axios";

const BASE_URL = "https://vtradellc.com/api/api/";

const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const fetchcategorylist = async () => {
  try {
    const response = await ApiService.get("category-list/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

export const fetchproducts = async () => {
  try {
    const response = await ApiService.get("products/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

export const fetchbrandlist = async () => {
  try {
    const response = await ApiService.get("brand-list/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const banner = async () => {
  try {
    const response = await ApiService.get("banners/1");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchfilter = async (categoryId) => {
  try {
    const response = await ApiService.get(`filter/?category=${categoryId}`);
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

export const fetchsearch = async (searchQuery) => {
  try {
    const response = await ApiService.get(`search/?search=${searchQuery}`);
    return response.data;
  } catch (error) {
    console.error("Error searching tours:", error);
    throw error;
  }
};

export const fetchProductDetail = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}product-detail/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product detail:", error);
    throw error;
  }
};

export const fetchbrandproduct = async (brandId) => {
  try {
    const response = await axios.get(`${BASE_URL}product-brand/${brandId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product detail:", error);
    throw error;
  }
};

export const fetchproductcategory = async (categoryId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}product-category/${categoryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching product detail:", error);
    throw error;
  }
};

export const fetchBeverages = async () => {
  try {
    const response = await ApiService.get("product-category/1");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchSpreads = async () => {
  try {
    const response = await ApiService.get("product-category/2");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchBabyMilkandFood = async () => {
  try {
    const response = await ApiService.get("product-category/3");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchTeaandcoffee = async () => {
  try {
    const response = await ApiService.get("product-category/4");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchpersonalcare = async () => {
  try {
    const response = await ApiService.get("product-category/5");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
export const fetchToiletries = async () => {
  try {
    const response = await ApiService.get("product-category/6");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
