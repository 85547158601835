import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Header from "./components/navbar/Header";
import Footer from "./components/navbar/Footer";
import Products from "./components/pages/Products";
import Brand from "./components/pages/Brand";
import ProductDetail from "./components/pages/ProductDetail";
import Brandproduct from "./components/pages/Brandproduct";
import Categoryproduct from "./components/pages/Categoryproduct";
import Loading from "./components/pages/Loading";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route
            exact
            path="/Brandproduct/:brandId"
            element={<Brandproduct />}
          ></Route>
          <Route
            exact
            path="/Categoryproduct/:categoryId"
            element={<Categoryproduct />}
          ></Route>
          <Route
            exact
            path="/products/:categoryId"
            element={<Products />}
          ></Route>
          <Route exact path="/brand" element={<Brand />}></Route>
          <Route
            exact
            path="/product-detail/:productId"
            element={<ProductDetail />}
          ></Route>
          <Route exact path="/Loading" element={<Loading />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
