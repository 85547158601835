// import React, { useEffect, useState } from "react";
// import { fetchbrandlist } from "./Apiservice";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { Link } from "react-router-dom";

// const Brand = () => {
//   const [brand, setBrand] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   useEffect(() => {
//     const getProducts = async () => {
//       try {
//         const data = await fetchbrandlist();
//         setBrand(data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     getProducts();
//   }, []);
//   React.useEffect(() => {
//     AOS.init(); // Initialize AOS on component mount
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error loading products.</div>;
//   return (
//     <div>
//       <div className="bg-black" style={{ height: "80px", width: "100%" }}></div>
//       <section id="team" className="team section">
//         <div className="container section-title" data-aos="fade-up">
//           <h2>Brand</h2>
//           <p>Our Brand</p>
//         </div>

//         <div className="brand-page-card container gy-4">
//           {brand.map((brand, index) => (
//             <div
//               className="col-lg-3 col-md-6 d-flex align-items-stretch"
//               data-aos="fade-up"
//               data-aos-delay={`${100 + index * 100}`}
//               key={brand.id}
//             >
//               {" "}
//               <Link to={`/Brandproduct/${brand.id}`}>
//                 <div className="team-member">
//                   <div className="member-img">
//                     <img
//                       src={brand.image}
//                       className="img-fluid"
//                       alt={brand.name}
//                     />
//                   </div>
//                   <div className="member-info">
//                     <h4>{brand.name}</h4>
//                   </div>
//                 </div>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Brand;

import React, { useEffect, useState } from "react";
import { fetchbrandlist } from "./Apiservice";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Loading from "./Loading";

const Brand = () => {
  const [brand, setBrand] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchbrandlist();
        setBrand(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>Error loading products.</div>;

  return (
    <div>
      <div className="bg-black" style={{ height: "80px", width: "100%" }}></div>
      <section id="team" className="team section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Brand</h2>
          <p>Our Brand</p>
        </div>

        <div className="brand-page-card container">
          <div className="row gy-4">
            {brand.map((brand, index) => (
              <div
                className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={`${100 + index * 100}`}
                key={brand.id}
              >
                <Link to={`/Brandproduct/${brand.id}`}>
                  <div className="team-member">
                    <div className="member-img">
                      <img
                        src={brand.image}
                        className="img-fluid"
                        alt={brand.name}
                      />
                    </div>
                    <div className="member-info">
                      <h4>{brand.name}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand;
