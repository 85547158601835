import React, { useEffect, useState } from "react";
import { fetchProductDetail, fetchproducts } from "./Apiservice"; // Update the import if necessary
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Loading from "./Loading";

const ProductDetail = () => {
  const { productId } = useParams(); // Extract productId from URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [realatedProducts, setRealatedProducts] = useState([]);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const data = await fetchProductDetail(productId);
        setProduct(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const getProducts = async () => {
      try {
        const data = await fetchproducts();
        setRealatedProducts(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    getProducts();

    getProduct();
  }, [productId]);

  const PrevArrow = ({ onClick }) => (
    <button className="arrow prev" onClick={onClick}>
      &#9664; {/* You can use any icon or text for the arrow */}
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="arrow next" onClick={onClick}>
      &#9654; {/* You can use any icon or text for the arrow */}
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  React.useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>Error loading product details.</div>;

  return (
    <div>
      <div className="bg-white" style={{ height: "80px", width: "100%" }}></div>
      <div
        className="container section-title text-center mt-5"
        data-aos="fade-up"
      >
        <h2>Product</h2>
        <p>Products Detail</p>
      </div>

      {product && (
        <div className="d-flex justify-content-center">
          <div className="row container gy-3">
            <div className="col-lg-6">
              <img
                src={product.image}
                alt="img"
                style={{
                  width: "100%",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="col-lg-6 ">
              <h1>{product.name}</h1>
              <p style={{ textAlign: "justify" }}>{product.description}</p>
              <p>Price: ${product.price}</p>
              <p>Quantity: {product.quantity}</p>
              <p>Shelf Life: {product.shelf_life}</p>
            </div>
          </div>
        </div>
      )}

      <section id="team" className="team section">
        <div className="container section-title text-center" data-aos="fade-up">
          <h2>Product</h2>
          <p>Related Products</p>
        </div>

        <div className="container">
          {realatedProducts.length > 0 ? (
            <Slider {...settings}>
              {realatedProducts.map((product) => (
                <div
                  className="product-card"
                  key={product.id}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Link to={`/product-detail/${product.id}`}>
                    <div className="member-img">
                      <img
                        src={product.image}
                        className="img-fluid"
                        alt={product.name}
                      />
                    </div>
                    <div className="product-info">
                      <h4>{product.name}</h4>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          ) : (
            <p>No related products available.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
