// import React, { useEffect, useState } from "react";
// import { fetchproducts, fetchfilter } from "./Apiservice";
// import { Link } from "react-router-dom";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { FaSearch } from "react-icons/fa";

// const Dropdown = ({ options, selectedOption, setSelectedOption }) => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const handleSelectClick = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const handleOptionClick = (option) => {
//     setSelectedOption(option);
//     setIsDropdownOpen(false);
//   };

//   React.useEffect(() => {
//     AOS.init(); // Initialize AOS on component mount
//   }, []);
//   return (
//     <div className="product-filter-dropdown">
//       <div
//         className={`product-filter-select ${
//           isDropdownOpen ? "select-clicked" : ""
//         }`}
//         onClick={handleSelectClick}
//       >
//         <span className="product-filter-selected">{selectedOption}</span>
//         <div
//           className={`product-filter-caret ${
//             isDropdownOpen ? "product-filter-caret-rotate" : ""
//           }`}
//         ></div>
//       </div>
//       <ul
//         className={`product-filter-menu ${
//           isDropdownOpen ? "product-filter-menu-open" : ""
//         }`}
//       >
//         {options.map((option, index) => (
//           <li
//             key={index}
//             className={`product-filter-option ${
//               selectedOption === option ? "product-filter-active" : ""
//             }`}
//             onClick={() => handleOptionClick(option)}
//           >
//             {option}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// const Products = () => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const [selectedProduct, setSelectedProduct] = useState();
//   const [filter, setFilter] = useState([]);

//   useEffect(() => {
//     const getProducts = async () => {
//       try {
//         const data = await fetchproducts();
//         setProducts(data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     getProducts();
//   }, []);
//   useEffect(() => {
//     const getfilter = async () => {
//       try {
//         const data = await fetchfilter();
//         setFilter(data);
//       } catch (error) {}
//     };

//     getfilter();
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error loading products.</div>;

//   return (
//     <div>
//       <section id="team" className="team section mt-5">
//         <div className="product-haeder container">
//           <div className="container section-title" data-aos="fade-up">
//             <h2>Products</h2>
//             <p>Our Products</p>
//           </div>
//           <div className="product-search">
//             <div className="input-group ">
//               <div className="form-outline" data-mdb-input-init>
//                 <input
//                   id="search-input"
//                   type="search"
//                   className="form-control"
//                   placeholder=" " // This ensures the label moves on input
//                 />
//                 <label className="form-label" htmlFor="search-input">
//                   Search
//                 </label>
//                 <button
//                   id="search-button"
//                   type="button"
//                   className="btn btn-primary"
//                 >
//                   <FaSearch />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="container product-page">
//           <div className="product-filter">
//             <div className="product-filter-heading">Filter</div>

//             <div className="product-filter-dropdownbox">
//               <h1>Product</h1>
//               <Dropdown
//                 selectedOption={selectedProduct}
//                 setSelectedOption={setSelectedProduct}
//               />
//             </div>
//           </div>
//           <div className="row gy-4">
//             {products.map((product, index) => (
//               <div
//                 className="col-lg-3 col-md-6 d-flex align-items-stretch"
//                 data-aos="fade-up"
//                 data-aos-delay={`${100 + index * 100}`}
//                 key={product.id}
//               >
//                 <Link to={`/product-detail/${product.id}`}>
//                   <div className="team-member">
//                     <div className="member-img">
//                       <img
//                         src={product.image}
//                         className="img-fluid"
//                         alt={product.name}
//                       />
//                     </div>
//                     <div className="member-info">
//                       <h4>{product.name}</h4>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Products;

// import React, { useEffect, useState } from "react";
// import { fetchproducts, fetchcategorylist, fetchsearch } from "./Apiservice";
// import { Link } from "react-router-dom";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { FaSearch } from "react-icons/fa";
// import Dropdown from "./Dropdown";

// const Products = () => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [categories, setCategories] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);

//   useEffect(() => {
//     const getProducts = async () => {
//       try {
//         const data = await fetchproducts();
//         setProducts(data);
//         setFilteredProducts(data); // Initially, show all products
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     const getCategories = async () => {
//       try {
//         const data = await fetchcategorylist();
//         setCategories(data);
//       } catch (error) {
//         setError(error);
//       }
//     };

//     getProducts();
//     getCategories();
//   }, []);

//   useEffect(() => {
//     let updatedProducts = products;

//     // Filter by category
//     if (selectedCategory) {
//       updatedProducts = updatedProducts.filter(
//         (product) => product.category === selectedCategory
//       );
//     }

//     // Filter by search term
//     if (searchTerm) {
//       updatedProducts = updatedProducts.filter((product) =>
//         product.name.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     setFilteredProducts(updatedProducts);
//   }, [selectedCategory, searchTerm, products]);

//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   const handleCategoryChange = (category) => {
//     const selectedCategoryId = categories.find(
//       (cat) => cat.name === category
//     )?.id;
//     setSelectedCategory(selectedCategoryId || "");
//   };

//   React.useEffect(() => {
//     AOS.init(); // Initialize AOS on component mount
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error loading products.</div>;

//   return (
//     <div>
//       <section id="team" className="team section mt-5">
//         <div className="product-header container">
//           <div className="container section-title" data-aos="fade-up">
//             <h2>Products</h2>
//             <p>Our Products</p>
//           </div>
//           <div className="product-search">
//             <div className="input-group ">
//               <div className="form-outline" data-mdb-input-init>
//                 <input
//                   id="search-input"
//                   type="search"
//                   className="form-control"
//                   placeholder=" " // This ensures the label moves on input
//                   value={searchTerm}
//                   onChange={handleSearch}
//                 />
//                 <label className="form-label" htmlFor="search-input">
//                   Search
//                 </label>
//                 <button
//                   id="search-button"
//                   type="button"
//                   className="btn btn-primary"
//                 >
//                   <FaSearch />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="container product-page">
//           <div className="product-filter">
//             <div className="product-filter-heading">Filter</div>
//             <div className="product-filter-dropdownbox">
//               <h1>Category</h1>
//               <Dropdown
//                 options={categories.map((cat) => cat.name)} // Populate dropdown with category names
//                 selectedOption={
//                   categories.find((cat) => cat.id === selectedCategory)?.name ||
//                   ""
//                 }
//                 setSelectedOption={handleCategoryChange}
//               />
//             </div>
//           </div>
//           <div className="row gy-4">
//             {filteredProducts.map((product, index) => (
//               <div
//                 className=""
//                 data-aos="fade-up"
//                 data-aos-delay={`${100 + index * 100}`}
//                 key={product.id}
//                 style={{ height: "350px", width: "350px" }}
//               >
//                 <div className="team-member">
//                   <Link to={`/product-detail/${product.id}`}>
//                     <div className="member-img">
//                       <img
//                         src={product.image}
//                         className="img-fluid"
//                         alt={product.name}
//                       />
//                     </div>
//                     <div className="member-info">
//                       <h4>{product.name}</h4>
//                     </div>
//                   </Link>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Products;
import React, { useEffect, useState } from "react";
import { fetchproducts, fetchcategorylist, fetchsearch } from "./Apiservice";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaSearch } from "react-icons/fa";
import Dropdown from "./Dropdown";
import Loading from "./Loading";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchproducts();
        setProducts(data);
        setFilteredProducts(data); // Initially, show all products
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const getCategories = async () => {
      try {
        const data = await fetchcategorylist();
        setCategories(data);
      } catch (error) {
        setError(error);
      }
    };

    getProducts();
    getCategories();
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        try {
          const data = await fetchsearch(searchTerm);
          setFilteredProducts(data);
        } catch (error) {
          setError(error);
        }
      } else {
        setFilteredProducts(products);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  useEffect(() => {
    let updatedProducts = products;

    // Filter by category
    if (selectedCategory) {
      updatedProducts = updatedProducts.filter(
        (product) => product.category === selectedCategory
      );
    }

    setFilteredProducts(updatedProducts);
  }, [selectedCategory, products]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCategoryChange = (category) => {
    const selectedCategoryId = categories.find(
      (cat) => cat.name === category
    )?.id;
    setSelectedCategory(selectedCategoryId || "");
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);

  if (loading) return <Loading />;

  if (error) return <div>Error loading products.</div>;

  return (
    <div>
      <section id="team" className="team section mt-5">
        <div className="product-header container">
          <div className="container section-title" data-aos="fade-up">
            <h2>Products</h2>
            <p>Our Products</p>
          </div>
          <div className="product-search">
            <div className="input-group ">
              <div className="form-outline" data-mdb-input-init>
                <input
                  id="search-input"
                  type="search"
                  className="form-control"
                  placeholder=" "
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <label className="form-label" htmlFor="search-input">
                  Search
                </label>
                <button
                  id="search-button"
                  type="button"
                  className="btn btn-primary"
                >
                  <FaSearch />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container product-page">
          <div className="product-filter">
            <div className="product-filter-heading">Filter</div>
            <div className="product-filter-dropdownbox">
              <h1>Category</h1>
              <Dropdown
                options={categories.map((cat) => cat.name)} // Populate dropdown with category names
                selectedOption={
                  categories.find((cat) => cat.id === selectedCategory)?.name ||
                  ""
                }
                setSelectedOption={handleCategoryChange}
              />
            </div>
          </div>
          <div className="row gy-">
            {filteredProducts.map((product, index) => (
              <div
                className="col-12 col-md-6 col-lg-3 mb-4"
                data-aos="fade-up"
                data-aos-delay={`${100 + index * 100}`}
                key={product.id}
              >
                <div className="team-member col-12 ">
                  <Link to={`/product-detail/${product.id}`}>
                    <div className="member-img">
                      <img
                        src={product.image}
                        className="img-fluid "
                        alt={product.name}
                      />
                    </div>
                    <div className="member-info">
                      <h4>{product.name}</h4>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
