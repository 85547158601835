// import React from "react";
// import { Link } from "react-router-dom";

// const Header = () => {
//   return (
//     <div>
//       <header
//         id="header"
//         className="header d-flex align-items-center fixed-top"
//       >
//         <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
//           <Link to="/" className="logo d-flex align-items-center">
//             <img src="assets/img/vtradelogo1.png" alt="" />
//             {/* <h1 className="sitename">V Trade</h1> */}
//           </Link>

//           <nav id="navmenu" className="navmenu">
//             <ul>
//               <li>
//                 <Link to="/" className="active">
//                   Home
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/about">About</Link>
//               </li>
//               <li>
//                 <Link to="/products">Products</Link>
//               </li>
//               <li>
//                 <Link to="/brand">Brands</Link>
//               </li>
//               <li>
//                 <Link to="/catalogue">E-Catalogue</Link>
//               </li>
//               <li>
//                 <Link to="/contact">Contact</Link>
//               </li>
//             </ul>
//             <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
//           </nav>
//         </div>
//       </header>
//     </div>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };
  const toggleNavMenuclose = () => {
    setIsNavOpen(false);
  };

  return (
    <div>
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            <img src="/assets/img/vtradelogo1.png" alt="Logo" />
            {/* <h1 className="sitename">V Trade</h1> */}
          </Link>

          <i
            className="mobile-nav-toggle d-xl-none bi bi-list"
            onClick={toggleNavMenu}
            style={{ color: "black" }}
          ></i>

          <nav
            id="navmenu"
            className={`navmenu ${isNavOpen ? "navmenu-open" : ""}`}
          >
            <ul>
              <li>
                <Link to="/" className="active">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/products/:categoryId">Products</Link>
              </li>
              <li>
                <Link to="/brand">Brands</Link>
              </li>
              {/* <li>
                <Link to="/catalogue">E-Catalogue</Link>
              </li> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>

          <nav
            id="mobilenavmenu"
            className={`mobilenavmenubar ${isNavOpen ? "open" : ""}`}
          >
            <div onClick={toggleNavMenuclose} className="navclose">
              <IoCloseSharp style={{ fontSize: "25px" }} />
            </div>
            <hr></hr>
            <ul>
              <li>
                <Link to="/" className="active" onClick={toggleNavMenuclose}>
                  Home
                </Link>
              </li>
              <hr></hr>
              <li>
                <Link to="/about" onClick={toggleNavMenuclose}>
                  About
                </Link>
              </li>
              <hr></hr>
              <li>
                <Link to="/products/:categoryId" onClick={toggleNavMenuclose}>
                  Products
                </Link>
              </li>
              <hr></hr>
              <li>
                <Link to="/brand" onClick={toggleNavMenuclose}>
                  Brands
                </Link>
              </li>
              <hr></hr>
              {/* <li>
                <Link to="/catalogue" onClick={toggleNavMenuclose}>
                  E-Catalogue
                </Link>
              </li> */}
              <hr></hr>
              <li>
                <Link to="/contact" onClick={toggleNavMenuclose}>
                  Contact
                </Link>
              </li>
              <hr></hr>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
