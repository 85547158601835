import React, { useEffect, useState, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  fetchcategorylist,
  fetchproducts,
  fetchbrandlist,
  fetchBeverages,
  fetchSpreads,
  fetchBabyMilkandFood,
  fetchTeaandcoffee,
  fetchpersonalcare,
  fetchToiletries,
  banner,
} from "./Apiservice";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import Loading from "./Loading";
import Lottie from "lottie-react";
import animatetrolly from "./animate-trolly.json";

const Home = () => {
  const [state, setState] = useState({
    categories: [],
    products: [],
    brand: [],
    spreads: [],
    beverages: [],
    babyMilkandFood: [],
    teaandcoffee: [],
    personalcare: [],
    toiletries: [],
    loading: true,
    error: null,
  });

  const [activeSlide, setActiveSlide] = useState(0);

  const slides = [
    {
      src: "../../assets/img/V-tradebanner.jpg",
      alt: " Image #1",
      title: "Welcome to VTRADE",
      description: "",
    },
    {
      src: "../../assets/img/banner2.jpg",
      alt: " Image #2",
      title: "Welcome to VTRADE",
      description: "",
    },
    {
      src: "../../assets/img/banner3.avif",
      alt: " Image #3",
      title: "Welcome to VTRADE",
      description: "",
    },
  ];

  const goToSlide = useCallback(
    (index) => {
      setActiveSlide((prevSlide) => (index + slides.length) % slides.length);
    },
    [slides.length]
  );

  const nextSlide = () => {
    goToSlide(activeSlide + 1);
  };

  const prevSlide = () => {
    goToSlide(activeSlide - 1);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 6000);
    return () => clearInterval(intervalId);
  }, [nextSlide]);

  const setPartialState = (partialState) =>
    setState((prevState) => ({ ...prevState, ...partialState }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          categories,
          brand,
          products,
          beverages,
          spreads,
          babyMilkandFood,
          teaandcoffee,
          personalcare,
          toiletries,
        ] = await Promise.all([
          fetchcategorylist(),
          fetchbrandlist(),
          fetchproducts(),
          fetchBeverages(),
          fetchSpreads(),
          fetchBabyMilkandFood(),
          fetchTeaandcoffee(),
          fetchpersonalcare(),
          fetchToiletries(),
        ]);

        setPartialState({
          categories,
          brand,
          products,
          beverages,
          spreads,
          babyMilkandFood,
          teaandcoffee,
          personalcare,
          toiletries,
          loading: false,
        });
      } catch (error) {
        setPartialState({ error, loading: false });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  if (state.loading) return <Loading />;

  if (state.error) {
    // return <div>Error: {state.error.message}</div>;
    return <Loading />;
  }

  const {
    beverages,
    spreads,
    babyMilkandFood,
    teaandcoffee,
    personalcare,
    toiletries,
    brand,
    products,
  } = state;

  const PrevArrow = ({ onClick }) => (
    <button className="arrow prev" onClick={onClick}>
      &#9664;
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="arrow next" onClick={onClick}>
      &#9654;
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section id="hero" className="hero carousel">
        <button
          className="slider-button slider-button-prev"
          onClick={prevSlide}
        >
          <BsChevronLeft />
        </button>
        <button
          className="slider-button slider-button-next"
          onClick={nextSlide}
        >
          <BsChevronRight />
        </button>
        <ul className="slides">
          {slides.map((slide, index) => (
            <li
              key={index}
              className={`slide ${index === activeSlide ? "active" : ""}`}
            >
              <img src={slide.src} alt={slide.alt} />
              <div className="slide-content">
                <h2>{slide.title}</h2>
                <p>{slide.description}</p>
              </div>
            </li>
          ))}
        </ul>
        <div className="slides-circles">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`circle ${index === activeSlide ? "active" : ""}`}
            ></div>
          ))}
        </div>

        {/* <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3"></use>
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0"></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9"></use>
          </g>
        </svg> */}
      </section>

      {/* <section>
        <div className="home-slider">
          <div className="ag-spotlight-moving_box">
            <div className="ag-spotlight-moving_spotlight ag-spotlight-moving_spotlight-right"></div>
            <div className="ag-spotlight-moving_spotlight ag-spotlight-moving_spotlight-left"></div>
            <div className="ag-spotlight-moving_airplane"></div>
            <div className="welcome-text">
              Welcome to Vtrade <br></br>Dubai | India
            </div>
          </div>
        </div>
      </section> */}
      <div
        className="container mt-5 section-title text-center"
        data-aos="fade-up"
      >
        <h2>Category</h2>
        <p>Our Categories</p>
      </div>

      <div className="container">
        <Slider {...settings}>
          {state.categories.map((category, index) => (
            <div
              className="product-card"
              key={index}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Link to={`/Categoryproduct/${category.id}`}>
                <div className="member-img">
                  <img
                    src={category.image}
                    className="img-fluid"
                    alt={category.name}
                  />
                </div>
                <div className="product-info">
                  <h4>{category.name}</h4>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>

      {/* --------------------------------------------beverages------------------------------------------- */}

      <section id="team" className="team section bg-light teamgradian">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>Beverages</h2>
          <p>Our Beverages</p>
        </div>

        <div className="container">
          {beverages && beverages.length > 0 ? (
            <Slider {...settings}>
              {beverages.map((beverage) => (
                <div
                  className="product-card-threeline"
                  key={beverage.id}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Link to={`/product-detail/${beverage.id}`}>
                    <div className="member-img">
                      <img
                        src={beverage.image}
                        className="img-fluid"
                        alt={beverage.name}
                      />
                    </div>
                    <div className="product-info">
                      <h4>{beverage.name}</h4>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          ) : (
            <p>No beverages available.</p>
          )}
        </div>

        <div className="container">
          {beverages && beverages.length > 0 ? (
            <Slider {...settings}>
              {[...beverages].reverse().map((beverage) => (
                <div
                  className="product-card-threeline"
                  key={beverage.id}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Link to={`/product-detail/${beverage.id}`}>
                    <div className="member-img">
                      <img
                        src={beverage.image}
                        className="img-fluid"
                        alt={beverage.name}
                      />
                    </div>
                    <div className="product-info">
                      <h4>{beverage.name}</h4>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          ) : (
            <p>No beverages available.</p>
          )}
        </div>
      </section>

      {/* --------------------------------------------spreads------------------------------------------- */}
      <section id="team" className="team section">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>Spreads</h2>
          <p>Our Spreads</p>
        </div>

        <div className="container">
          <Slider {...settings}>
            {spreads.map((spreads) => (
              <div
                className="product-card-threeline"
                key={spreads.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${spreads.id}`}>
                  <div className="member-img">
                    <img
                      src={spreads.image}
                      className="img-fluid"
                      alt={spreads.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{spreads.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>

        <div className="container">
          <Slider {...settings}>
            {[...spreads].reverse().map((spreads) => (
              <div
                className="product-card-threeline"
                key={spreads.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${spreads.id}`}>
                  <div className="member-img">
                    <img
                      src={spreads.image}
                      className="img-fluid"
                      alt={spreads.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{spreads.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* --------------------------------------------babyMilkandFood------------------------------------------- */}

      <section id="team" className="team section bg-light teamgradian">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>BabyMilkandFood</h2>
          <p>Our BabyMilkandFood</p>
        </div>

        <div className="container">
          {/* First Slider: Normal order */}
          <Slider {...settings}>
            {babyMilkandFood.map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>

          {/* Second Slider: Reverse order */}
          <Slider {...settings}>
            {[...babyMilkandFood].reverse().map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* --------------------------------------------teaandcoffee------------------------------------------- */}

      <section id="team" className="team section">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>Tea and Coffee</h2>
          <p>Our Tea and Coffee</p>
        </div>

        <div className="container">
          <Slider {...settings}>
            {teaandcoffee.map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
          <Slider {...settings}>
            {[...teaandcoffee].reverse().map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* --------------------------------------------personalcare------------------------------------------- */}
      <section id="team" className="team section bg-light teamgradian">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>Personal Care</h2>
          <p>Our Personal Care</p>
        </div>

        <div className="container">
          <Slider {...settings}>
            {personalcare.map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
          <Slider {...settings}>
            {[...personalcare].reverse().map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* --------------------------------------------toiletries------------------------------------------- */}
      <section id="team" className="team section">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>Toiletries</h2>
          <p>Our Toiletries</p>
        </div>

        <div className="container">
          <Slider {...settings}>
            {toiletries.map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
          <Slider {...settings}>
            {[...toiletries].reverse().map((item) => (
              <div
                className="product-card-threeline"
                key={item.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${item.id}`}>
                  <div className="member-img">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{item.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* --------------------------------------------product------------------------------------------- */}
      <div className="image-container">
        <div className="image-content">
          {/* Your content here */}
          <Lottie animationData={animatetrolly} className="moving-image" />
        </div>
      </div>
      <section id="team teamsc" className="team section bg-light teamgradian">
        <div
          className="container section-title text-center mt-5"
          data-aos="fade-up"
        >
          <h2>product</h2>
          <p>Our product</p>
        </div>

        <div className="container">
          <Slider {...settings}>
            {products.map((product) => (
              <div
                className="product-card-threeline"
                key={product.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${product.id}`}>
                  <div className="member-img">
                    <img
                      src={product.image}
                      className="img-fluid"
                      alt={product.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{product.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
        <div className="container">
          <Slider {...settings}>
            {[...products].reverse().map((product) => (
              <div
                className="product-card-threeline"
                key={product.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/product-detail/${product.id}`}>
                  <div className="member-img">
                    <img
                      src={product.image}
                      className="img-fluid"
                      alt={product.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{product.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* --------------------------------------------homemarq------------------------------------------- */}
      <div className="homemarq">
        <div className="homemarq-scroll" style={{ "--t": "20s" }}>
          <div>
            <span>Pringles</span>
            <span>Cheerios</span>
            <span>Oreos</span>
            <span>Doritos</span>
            <span>Snickers</span>
            <span>KitKat</span>
            <span>Coca-Cola</span>
            <span>Pepsi</span>
            <span>Red Bull</span>
          </div>
          <div>
            <span>Tropicana</span>
            <span>Nestea</span>
            <span>Kraft</span>
            <span>Tillamook</span>
            <span>Nestlé</span>
            <span>nesquik</span>
            <span>ovaltin</span>
            <span>coffemate</span>
          </div>
        </div>

        <div className="homemarq-scroll" style={{ "--t": "15s" }}>
          <div>
            <span>Tropika</span>
            <span>Dove</span>
            <span>lifeboy</span>
            <span>Axe</span>
            <span>suave</span>
            <span>Sunslik</span>
            <span>Dettol</span>
            <span>Confort</span>
            <span>Cif</span>
            <span>Rexona</span>
          </div>
          <div>
            <span>Moster</span>
            <span>evian</span>
            <span>Goodday</span>
            <span>Doritos</span>
            <span>Snickers</span>
            <span>KitKat</span>
            <span>Coca-Cola</span>
            <span>Pepsi</span>
            <span>Red Bull</span>
            <span>Rexona</span>
          </div>
        </div>

        <div className="homemarq-scroll" style={{ "--t": "15s" }}>
          <div>
            <span>Tropika</span>
            <span>Dove</span>
            <span>lifeboy</span>
            <span>Axe</span>
            <span>suave</span>
            <span>Sunslik</span>
            <span>Dettol</span>
          </div>
          <div>
            <span>Tropicana</span>
            <span>Nestea</span>
            <span>Kraft</span>
            <span>Tillamook</span>
            <span>Nestlé</span>
            <span>nesquik</span>
            <span>ovaltin</span>
          </div>
        </div>
      </div>

      {/* --------------------------------------------Brand------------------------------------------- */}
      <section id="team" className="team section">
        <div className="container section-title text-center" data-aos="fade-up">
          <h2>Brand</h2>
          <p>Our Brand</p>
        </div>

        <div className="container">
          <Slider {...settings}>
            {brand.map((brand) => (
              <div
                className="product-card"
                key={brand.id}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Link to={`/Brandproduct/${brand.id}`}>
                  <div className="member-img">
                    <img
                      src={brand.image}
                      className="img-fluid"
                      alt={brand.name}
                    />
                  </div>
                  <div className="product-info">
                    <h4>{brand.name}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Home;
